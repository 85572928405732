<template>
	<div class="home">
		Тут ничего нет
	</div>
</template>

<script>
// @ is an alias to /src

export default {
	name: 'Home',
	components: {
	}
}
</script>
